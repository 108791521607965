.cart-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-title {
    width: 100%;
    margin: 50px 30px 20px 30px;
    font-size: 40px;
    font-weight: 600;
    color: var(--dark-green);

}

.cart-info {
    display: flex;
    gap: 20px;
    width: 100%;
}

.cart-items {
    flex: 2;

}

.cart-items hr {
    border: 1px solid rgb(234, 234, 234);
    margin: 10px;
}

.cart-cost {
    flex: 1;
}

.cart-total > div {
    display: flex;
    justify-content: space-between;
}

.cart-total hr {
    border: 1px solid rgb(234, 234, 234);
    margin: 10px;
}

.cart-container-info div {
    padding: 10px;
}

.cart-container-info .mapboxgl-map {
    overflow: hidden;
    padding: 0;
    max-height: 350px;
}

.cart-container-info .mapboxgl-canvas {
    width: 100% !important;
    height: 100% !important;
}

.all-items-action {
    display: flex;
    gap: 20px;

}

.delete-all   {
    border: none;
    background-color: white;
    cursor: pointer;
    font-size: 16px;
    color: red; 
}

.cart-items-list {
    width: 100%;
}

.cart-payment-button button{
    border: none;
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
    font-size: 15px;
    min-width: 80px;
    color: #26585f;
    cursor: pointer;
    background-color: var(--yellow);
}

.empty-orders {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: rgb(189, 189, 189);
    font-size: 40px;
}

.empty-orders .css-10vyf6v {
    width: 70px !important;
    height: 70px !important;
}


@media screen and (max-width: 768px) {
    .cart-info {
        flex-direction: column;
    }

    .cart-container {
        width: 100%;
    }
    
    .cart-container .container {

    }

    .cart-items {
        padding: 0 !important;
    }
    
    .cart-title {
        width: 100%;
        margin: 40px 10px 20px 10px;
        font-size: 30px;
        font-weight: 600;
        color: var(--dark-green);
    
    }
    
    .cart-info {
        gap: 5px;
    }
    
    .cart-items hr {
        border: 1px solid rgb(234, 234, 234);
        margin: 2px;
    }
    
    .cart-total hr {
        border: 1px solid rgb(234, 234, 234);
        margin: 10px;
    }
    
    .cart-container-info div {
        padding: 5px;
    }
    
    .cart-container-info .mapboxgl-map {
        width: 100%;
        height: 300px !important;
    }
    
    .all-items-action {
        gap: 5px;
    
    }
    
    .delete-all   {
        font-size: 16px;
        color: red; 
    }
    
    .cart-items-list {
        width: 100%;
        max-width: 400px;
        min-width: 370px;
        padding: 0;
    }
    
    .cart-payment-button button{
        border: none;
        padding: 10px;
        font-weight: bold;
        border-radius: 5px;
        font-size: 15px;
        min-width: 40px;
        color: #26585f;
        cursor: pointer;
        background-color: var(--yellow);
    }

    .empty-orders {
        font-size: 25px;
    }

    .empty-orders .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-20bmp1-MuiSvgIcon-root {
        width: 40px;
        height: 40px;
    }
}