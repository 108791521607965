.events-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 0;
  max-width: 600px;
  margin: 0 auto;
  overflow: auto;
}

.event-card {
  background-color: var(--light-gray);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.event-card__image-wrapper {
  position: relative;
  width: 100%;
  background-color: #ccc;
  border-radius: 20px;
  overflow: hidden;
}

.event-card__image {
  width: 100%;
  height: auto;
  display: block;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.event-card__date {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #fff;
  color: #333;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  text-align: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.event-card__date-day {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
}

.event-card__date-month-time {
  font-size: 0.875rem;
  color: #888;
  line-height: 1.2;
}

.event-card__date-month-time span {
  display: block;
}

.event-card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.5rem 1.5rem;
  color: #fff;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-height: 30%;
}

.event-card__category {
  font-size: 1.3rem;
  text-transform: uppercase;
  opacity: 0.6;
}

.event-card__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-card__title {
  font-size: 1.2rem;
  font-weight: 500;
}

.event-card__price {
  font-size: 1.4rem;
  font-weight: 600;
}

.event-card__description-block {
  padding: 1rem;
  background-color: #f7f7f7;
}

.event-card__description-title {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.event-card__description-text {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.4;
}

@media screen and (max-width: 600px) {
  .event-card__category {
    font-size: 1rem;
  }

  .event-card__title {
    font-size: 1rem;
  }

  .event-card__price {
    font-size: 1.2rem;
  }

  .event-card__description-title {
    font-size: 1.1rem;
  }

  .event-card__description-text {
    font-size: 1rem;
  }
}
