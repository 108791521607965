.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 30px 10px;
  gap: 15px;
}

.profile-info > img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.profile-info .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.profile-info .user-info .created-at {
  color: gray;
  font-size: 15px;
}


.profile-info .user-info .city {
    display: flex;
    align-items: center;
    font-size: 17px;
}

.profile-info .user-info .name {
  font-size: 40px;
  font-weight: 600;
}

.profile-info .options .red {
  color: rgb(210, 0, 0);
}

.profile-info .options {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
}

.profile-info .options p:last-child {
  border-bottom: 1px solid lightgray;
}

.profile-info .options p {
  padding: 15px 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-top: 1px solid lightgray;
}

@media screen and (max-width: 768px) {
  .profile-info > img {
    width: 150px;
    height: 150px;
  }

  .profile-info .user-info .name {
    font-size: 30px;
  }


}