.cart-item-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart-item-info .status.red {
  color: red;
}

.cart-item-info .status.gray {
  color: gray;
}

.cart-item-info .status.green {
  color: green;
}


@media screen and (max-width: 768px) {
    .cart-item-info{ 
        gap: 8px;
    }

    .cart-item-info .status{
        font-size: 10px;
    }
}