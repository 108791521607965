.categories {
  width: 100%;
  height: 100%;
  border: none;
  border-bottom: 2px solid rgb(232, 232, 232);
  display: flex;
  justify-content: center;
  background-color: white;
}

.category-list.center {
  justify-content: center;
}

.category-list {
  max-width: 1300px;
  margin: 0 auto;
  width: 90%;
  padding: 25px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  justify-content: space-between;
  -webkit-overflow-scrolling: touch;
}

.category-list::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 990px) {
  .category-list.center {
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .category-list {
    padding: 7px 0;
  }
}
