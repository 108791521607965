.cart-categories {
    width: 100%;
    background-color: white;
    height: 50px;
    /* border-top: 1px solid rgba(0, 0, 0, 0.755); */
}

.cart-categories-list {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.cart-categories-list li {
    list-style: none;
    flex: 1;
    text-align: center;
    font-weight: 600;
    color: var(--dark-green);
    cursor: pointer;
    height: 100%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-categories-list li.active {
    color: var(--yellow);
}

.cart-categories-list li:hover {
    color: var(--yellow);
}

@media screen and (max-width: 768px) {
    .cart-categories {
        width: 100%;
        height: 35px;
    }
    
    .cart-categories-list li {
        padding: 10px;
        font-size: 10px;
        height: 100%;
    }

}