.category-item {
  min-width: 130px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.category-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.category-item img.visible {
    opacity: 1;
  }
  
  .category-item img.hidden {
    opacity: 0;
  }

.category-item img.active {
  box-shadow: 1px 1px 20px var(--yellow);
}

.category-item-skeleton {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .category-item {
    height: 80px;
    font-size: 8px;
    gap: 6px;
    min-width: 75px;
    text-align: center;
  }

  .category-item img {
    width: 60px;
    height: 60px;
  }

  .category-item-skeleton {
    width: 60px;
    height: 60px;
    margin-bottom: 6px;
  }

  .category-item img.active {
    box-shadow: 1px 1px 10px var(--yellow);
  }
}
