.product-detail-types {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
}

.product-detail-type {
  padding: 10px 20px;
  background-color: var(--dark-green);
  color: white;
  font-weight: 600;
}

.product-detail-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 20px;
}

.product-detail-info .name {
  font-size: 30px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.product-detail-info .price {
  font-weight: 700;
  font-size: 22px;
  color: rgb(72, 72, 72);
  margin-top: 10px;
}

.product-detail-info .description {
  font-size: 16px;
  color: rgb(72, 72, 72);
  margin-top: 15px;
}

.product-images {
  max-width: 600px;
  max-height: 400px;
  margin: 20px auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
}

.swiper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.favourite-item .MuiSvgIcon-root {
  color: var(--yellow);
  width: 35px;
  height: 35px;
}

.favourite-item {
  font-weight: 600;
  cursor: pointer;
  z-index: 10;
}

.swiper-slide img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}

.swiper-scrollbar {
  display: none;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: var(--dark-green);
  font-family: none;
}

.swiper-button-prev:after {
  content: '◀';
}

.swiper-button-next:after {
  content: '▶';
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  position: relative;
  max-height: 90%;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.modal-overlay:hover .modal-content {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .product-detail {
    position: relative;
  }

  .modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
  }
  
  .swiper-wrapper {
    width: 90%;
    height: 100%;
  }

  .favourite-item {
    padding: 7px 10px;
  }
}
