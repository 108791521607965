.product-item {
  width: 250px;
  height: 350px;
  background-color: transparent;
  perspective: 1000px;
  cursor: pointer;
}

.product-item-content {
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  border-radius: 10px;

}

.product-image {
  height: 50%;
  padding: 3px;
}

.product-item img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
  padding: 3px;
  object-fit: contain;
}

.product-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  gap: 10px;
  max-height: 100px;
  overflow: auto;
}

.product-price {
  font-weight: bold;
  color: var(--dark-green);
  font-size: 17px;
}

.product-name {
  font-size: 17px;
  font-weight: 600;
}

.product-item-buttons {
  position: absolute;
  justify-content: space-around;
  display: flex;
  bottom: 15px;
  gap: 10px;
  width: 84%;
}

.product-item-buttons button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  color: var(--dark-green);
}

.show-more-button {
  background-color: var(--dark-gray);
}

.product-quantity-controls {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
}

.product-quantity-controls button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .product-item {
    min-width: 120px;
    max-width: 150px;
    width: 100%; /* Change width to a percentage for better responsiveness */
    height: 170px;
    border-radius: 3px;
  }

  .product-item img {
    border-radius: 10px 10px 0 0;
    
  }

  .product-info {
    padding: 3px 10px;
    gap: 3px;
    max-height: 40px;
  }

  .product-price {
    font-size: 10px;
  }

  .product-name {
    font-size: 8px;
  }

  .product-item-buttons {
    bottom: 3px;
    justify-content: center;
    padding: 2px;
    margin-bottom: 5px;
  }

  .product-item-buttons button {
    padding: 5px 7px;
    border-radius: 3px;
    font-size: 5px;
  }

  .product-quantity-controls {
    display: flex;
    gap: 1px;
    align-items: center;
    font-weight: 600;
    font-size: 8px;
    min-width: 30px;
  }
  
  .product-quantity-controls button {
    padding: 3px 7px;
    border-radius: 3px;
    font-size: 10px;
  }

}
