.addresses-list {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.address-item {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 15px;
  gap: 20px;
  border-radius: 15px;
  width: 100%;
  border: 1px solid #ccc;
  transition: all 0.3s;
}

.address-item.active {
    background-color: var(--yellow);
    color: white;
}

.address-item-info {
}

.address-name {
  font-weight: 500;
  font-size: 15px;
}
