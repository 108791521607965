.ticket-qr {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ticket-qr__image {
  width: 70%;
  height: 70%;
  object-fit: contain;
  padding: 10px;
  border-radius: 8px;
  background: #f9f9f9;
}

.ticket-status {
  font-size: 18px;
  margin-top: 5px;
  font-weight: bold;
}

.ticket-actions {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  flex-direction: column;
  width: 60%;
}

.ticket-action-btn {
  padding: 15px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  width: 100%;
  font-weight: 600;
}

.ticket-action-btn.add-photo {
  background: #007bff;
  color: #fff;
}

.ticket-action-btn.cancel-ticket {
  background: #fff;
  color: #dc3545;
}

@media screen and (max-width: 768px) {
  .event-detail__third-card {
    gap: 0.5px;
  }

  .ticket-actions {
    gap: 10px;
    margin-top: 25px;
    width: 100%;
  }

  .ticket-action-btn {
    padding: 10px 15px;
    font-size: 14px;
    width: 100%;
  }

  .ticket-status {
    font-size: 14px;
    margin-top: 5px;
    font-weight: bold;
  }
}
