.modal {
  width: 100%;
  height: 100vh;
  display: none;
}

.open-modal {
  display: block;
}

.black-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 99;
}

.modal-window {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 20px var(--yellow);
  border-radius: 10px;
  padding: 30px 20px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-window.open-modal-window {
  animation: slide 0.7s ease;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 20px;
  cursor: pointer;
  font-size: 30px;
  font-weight: 900;
  color: var(--dark-green);
  background-color: transparent;
}

.modal-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
  color: var(--dark-green);
}

.modal-info {
  width: 70%;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-form input,
.modal-form .select-input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid var(--dark-green);
  border-radius: 5px;
  outline: none;
  margin: 10px 0;
}

.register-text {
  font-size: 12px;
  margin: 5px;
  color: gray;
  text-align: center;
}

.modal-form .select-input {
  color: rgb(92, 92, 92);
}

.input-error {
  color: red;
  border-bottom: 1px solid red !important;
  margin: -10px;
}

.input-error::placeholder {
  color: red;
}

.error-text {
  color: red;
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.modal-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: var(--dark-green);
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
}

.modal-buttons .login-button {
  background-color: var(--yellow);
}

.forgot-password-text {
  width: auto;
  display: flex;
  justify-content: center;
  margin: 12px;
  text-decoration: underline;
  color: rgb(11, 11, 116);
  cursor: pointer;
}

.login-link-text {
  text-align: center;
  font-size: 16px;
}

.login-link-text span {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(0, 0, 133);
}

@keyframes slide {
  0% {
    transform: translate(-50%, -200%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.total-cost-modal {
  padding: 10px 20px;
  min-width: 90%;
}

.total-cost-modal .total-cost-title {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
  color: var(--dark-green);
}

.total-cost-modal .total-order-units-list {
  width: 100%;
  height: 100%;
  max-height: 270px;
  overflow-y: scroll;
  margin: 15px 0;
}

.total-cost-modal .title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
  border-top: 1px solid lightgray;
  padding: 10px 3px;
}

.total-cost-modal .order-unit {
  width: 100%;
  margin: 20px 0;
  grid-template-columns: 100px 1fr 200px;
}

.total-cost-modal .order-unit-img {
  width: 100px;
  height: 100px;
}

.total-cost-modal .unit-price {
  display: flex;
  justify-content: end;
  gap: 3px;
}
.total-cost-modal .unit-price span {
  padding-top: 2px;
  font-size: 5px;
}

.total-paycheck-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  gap: 10px;
}
.total-paycheck-content > div {
  display: flex;
  justify-content: space-between;
}
.total-paycheck-content > * {
  width: 100%;
}

.total-paycheck p {
  font-size: 20px;
  font-weight: 500;
}

/* Saved Cards */
.credit-card-modal-saved-cards {
  margin-bottom: 20px;
}

.credit-card-modal-saved-cards h4 {
  text-align: left;
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 10px;
}

.credit-card-modal-saved-cards label {
  display: block;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.credit-card-modal-saved-cards input[type='radio'] {
  margin-right: 10px;
}

.credit-card-modal-saved-cards label:hover {
}

.credit-card-modal-saved-cards .saved-cards-list {
  
}

.credit-card-modal-saved-cards .saved-cards-list div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.credit-card-modal-saved-cards .saved-cards-list .MuiSvgIcon-root {
  color: var(--dark-green);
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.saved-card-item .card-info {
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.saved-card-item .card-info p span {
  margin: 0 5px;
}

.add-new-card {
  background-color: transparent;
  font-size: 14px;
  text-align: center;
  width: 100%;
  color: var(--dark-green);
}

.tracking-button {
  background-color: var(--dark-green);
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  width: fit-content;
}

.shop-info-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.shop-info-container .tracking-shop-name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.shop-info-container img {
  width: 100px;
  height: 100%;
}

.shop-info-container p {
  font-size: 17px;
  overflow: hidden;
  width: fit-content;
}

.shop-info-container .icons {
  display: flex;
  gap: 10px;
  margin-left: 40px;
}

.card-icon {
  width: 35px;
  cursor: pointer;
  margin-right: 5px;
}



.shop-info-container .icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: lightgray;
  border-radius: 50%;
}

.shop-info-container
  .icons
  a
  .css-10vyf6v  {
  color: white;
  width: 20px;
  height: 20px;
}

.order-tracking-shop-info {
}

.order-tracking-delivery-address {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0 20px 5px;
}

.order-tracking-delivery-address img {
  width: 50px;
  height: 50px;
}

.order-tracking-title {
  font-size: 25px;
  font-weight: bold;
  margin: 10px 0;
  color: var(--dark-green);
}

.tracking.title {
  padding-bottom: 3px;
  margin-bottom: 3px !important;
}

.order-tracking-step {
  display: none;
  gap: 10px;
  padding: 20px 10px;
  margin-bottom: 10px;
}

.order-tracking-step.active {
  display: flex;
  align-items: center;
}

.order-tracking-step img {
  width: 30px;
  height: 30px;
}

.total-order-tracking-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 0 0 0;
  gap: 10px;
}

.total-order-tracking-content > div {
  display: flex;
  justify-content: space-between;
}
.total-order-tracking-content > * {
  width: 100%;
}

@media screen and (max-width: 857px) {
  .shop-info-container {
    gap: 5px;
  }

  .shop-info-container img {
    width: 70px;
  }

  .shop-info-container p {
    font-size: 15px;
  }

  .modal-window {
    border-radius: 10px;
    padding: 30px 20px;
    min-width: 90%;
  }

  .total-cost-modal .order-unit {
    margin: 10px 0;
    grid-template-columns: 60px 1fr 100px;
    width: 100%;
  }

  .total-cost-modal .title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 15px;
    border-top: 1px solid lightgray;
    padding: 10px 3px;
  }

  .total-cost-modal .order-unit-img {
    width: 60px;
    height: 60px;
  }

  .close-button {
    right: 15px;
    cursor: pointer;
    font-size: 25px;
    font-weight: 900;
    color: var(--dark-green);
  }

  .modal-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--dark-green);
  }

  .modal-info {
    width: 70%;
  }

  .total-cost-modal {
    padding: 0;
  }

  .tracking-button {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
  }
}
