.go-back-button {
  top: 30px;
  left: 50px;
  color: var(--yellow);
  cursor: pointer;
  z-index: 6;
}

@media screen and (max-width: 768px) {
  .go-back-button {
    left: 10px;
    top: 20px;
  }
}
