.cart-map {
  margin: 20px 0;
  position: relative;
}

.map-address {
  position: absolute;
  top: 10px; /* Position it near the top */
  left: 10px; /* Position it near the left */
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Slightly transparent background */
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  z-index: 1; /* Ensure it appears above the map */
}

#map-container {
  position: relative;
  width: 100%;
  height: 600px;
}

@media screen and (max-width: 768px) {
    .map-address {
        width: fit-content;
        max-width: 90%;
      }
}