.order-unit-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.order-unit {
  display: grid;
  grid-template-columns: 100px 1fr 150px;
  gap: 20px;
}

.order-unit-info .unit-name {
  font-weight: 600;
  font-size: 20px;
}
.order-unit .unit-price {
  font-weight: 600;
  font-size: 22px;
}
.order-unit .unit-price span {
  font-weight: 300;
  font-size: 15px;
}

.order-unit-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 20px;
}

.order-unit-info div {
  padding: 0;
}               

.quantity-controls {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
}

.quantity-controls button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .quantity-controls {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
  }

  .quantity-controls button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
  }

  img.order-unit-img {
    width: 80px;
    height: 80px;
  }

  .order-unit {
    display: grid;
    grid-template-columns: 80px 1fr 100px;
    gap: 10px;
  }

  .order-unit-info .unit-name {
    font-weight: 600;
    font-size: 13px;
  }
  .order-unit .unit-price {
    font-weight: 600;
    font-size: 13px;
  }
  .order-unit .unit-price span {
    font-weight: 300;
    font-size: 10px;
  }

  .order-unit-info {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 10px;
  }

  .order-unit-info div {
    padding: 0;
  }
}
