body {
  background-color: #ffffff;
  padding-bottom: 100px;
}

.main-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container {
  max-width: 1300px;
  width: 90%;
  margin: 0 auto;
}

.sticky-container {
  position: sticky;
  top: 0;
  z-index: 5;
}

.app {
  position: relative;
}

.page {
  width: 100%;
  height: 100%;
  position: relative;
}

.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999; 
  width: auto;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.alert-hidden {
  opacity: 0;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff; 
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  text-align: center; 
}

.modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.modal-content button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--yellow); 
  color: white; 
  transition: background-color 0.3s;
}

.modal-content button:hover {
  background-color: var(--yellow); 
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
}

.modal-content .close-button:hover {
  color: #333;
}


@media screen and (max-width: 768px) {
  .main-container {
    width: 95%;
  }
  .app {

    width: 100%;
  }
}
