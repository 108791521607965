.search-form {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 30px 0;
  justify-content: center;
}

.search-input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  width:70%;
}

.search-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--yellow);
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

/* .search-button:hover {
  background-color: rgb(255, 229, 81);
} */
