.tickets-container {
  position: relative;
}

.tickets-header {
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 30px 15px 30px;
}

.tickets-header__title {
  flex: 1;
  text-align: center;
  font-size: 1.5rem;
  margin-left: 10px;
}

.tickets-header__archive {
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer;
}

.tickets-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 70%;
  margin: 0 auto;
}

.tickets-archive {
  padding: 35px 35px 20px 10px;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 19px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .tickets-list {
    max-width: 90%;
    gap: 15px;
  }

  .tickets-header__title {
    font-size: 1.2rem;
  }

  .tickets-header__archive {
    font-size: 1rem;
  }
}
