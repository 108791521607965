.support {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.support-content {
    padding: 50px 20px;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.support-types {
    display: flex;
    width: 100%;
    background-color: rgb(231, 231, 231);
    padding: 5px;
    border-radius: 10px;
}

.support-types button {
    flex: 1;
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: 600;

}

.support-types button.active {
    flex: 1;
    background-color: var(--yellow);
    color: white;
    padding: 10px;
    border-radius: 10px;
}

.support-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;  
    width: 100%;
    text-align: center;
}

.support-form .phone-number a{
    color: var(--yellow);
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
}

.support-form input,
.support-form textarea {
    height: 150px;
    background-color: rgb(236, 236, 236);
    border: none;
    padding: 10px;
    border-radius: 10px;
    outline: none;
}
.support-form .address{
    font-size: 17px;
    color: gray;
}

.support-form button{
    background-color: var(--yellow);
    color: white;
    padding: 15px 20px;
    margin-top: 20px;
    font-weight: 600;
}