.product-list {
  padding: 50px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.container.fade .product-list {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.container .product-list {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.loading {
    text-align: center;
}

.product-item-skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0; /* Light background for the skeleton */
  border-radius: 10px; /* Rounded corners to match product item style */
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.product-item-skeleton .skeleton-image {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.skeleton-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.skeleton-info .skeleton-text {
  height: 20px;
  border-radius: 4px;
}

.skeleton-info .skeleton-text.short {
  width: 60%;
}

.skeleton-info .skeleton-text.long {
  width: 80%;
}

.no-products {
  text-align: center;
  width: 100vw;
  margin-left: 10px;
  font-size: 15px;
  color: #888;
}

@media screen and (max-width: 768px) {
  .product-list {
    width: 100%;
    padding: 20px 0;
    gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .product-item-skeleton {
    padding: 10px;
  }

  .product-item-skeleton .skeleton-image {
    height: 150px;
  }

  .skeleton-info .skeleton-text {
    height: 16px;
  }
}
