.search-result {
  display: grid;
  grid-template-columns: 100px 1fr 150px 50px;
  align-items: center;
  padding: 10px;
  gap: 20px;
  border-bottom: 1px solid #ccc;
  transition: all 0.3s;
}

.search-result:hover {
  border: 1px solid var(--yellow);
  box-shadow: 1px 1px 10px var(--yellow);
  overflow: visible;
}

.search-result div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.search-result img {
    max-width: 100%;
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin: 10px 0;
}

.search-result .product-name {
  font-weight: 500;
  font-size: 25px;
}

.search-result .item-cost {
  font-weight: 600;
  text-align: end;
}

@media screen and (max-width: 768px) {
  .search-result {
    display: grid;
    grid-template-columns: 70px 1fr 80px 25px;
    align-items: center;
    padding: 5px;
    gap: 10px;
    max-width: 400px;
  }

  .search-result img {
    width: 60px;
    height: 60px;
    margin: 5px;
  }

  .search-result .product-name {
    font-weight: 700;
    font-size: 14px;
  }

  .search-result .item-cost {
    font-size: 12px;
  }
}
