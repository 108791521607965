.buy-ticket {
  position: relative;
}

.buy-ticket-form {
  max-width: 600px;
  margin: 0 auto;

}

.buy-ticket-title {
  text-align: center;
  font-size: 20px;
  padding: 20px 0px;
}

.payment-section {
  border-top: 1px solid #e4e4e4;
  padding: 1rem 0;
}

.payment-section.double {
  border-bottom: 1px solid #e4e4e4;
}

.payment-section__label {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.payment-section__value {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  color: rgb(33, 33, 33);
}

.payment-section__sub {
  font-size: 0.9rem;
  color: #888;
}

.payment-subtitle {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.saved-cards-list {
  margin-bottom: 30px;
}

.buy-ticket-form .saved-card-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.saved-card-item .MuiSvgIcon-root {
  color: var(--dark-green);
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.card-wrapper {
  position: relative;
  background-color: var(--dark-gray);
  border-radius: 12px;
  padding: 1rem;
  margin: 15px 0 35px 0;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payment-new-card,
.payment-submit {
  display: block;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  padding: 1rem;
  margin: 1rem 0;
  cursor: pointer;
}

.payment-new-card {
  background-color: var(--dark-gray);
  color: #333;
}

.payment-submit {
  background-color: #f9c600;
  color: #333;
  font-weight: 600;
}

.no-saved-cards {
  margin: 0.5rem 0 1.5rem;
  color: rgb(159, 0, 0);
  
}

@media screen and (max-width: 768px) {

  .buy-ticket-form {
    max-width: 90%;
    margin: 0 auto;
  }

  .payment-page {
    padding: 0.75rem;
  }

  .payment-header__title {
    font-size: 1.1rem;
  }

  .payment-section__label {
    font-size: 1.3rem;
  }

  .payment-section__value {
    font-size: 1.1rem;
  }

  .payment-submit,
  .payment-new-card {
    font-size: 0.95rem;
    padding: 0.75rem;
  }
}
