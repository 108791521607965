body, html, * {
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}

img {
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --dark-green: #26585f;
  --yellow: #ffcc01;
  --dark-gray: rgb(226, 226, 226);
  --light-gray: #f6f7fa;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input,
button,
select {
  border: none;
  border-radius: 10px;
  outline: none;
}

button {
  cursor: pointer;
}

input[type="number"]:disabled,
input[type="text"]:disabled,
select:disabled,
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: rgb(229, 229, 229);
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

