.event-detail {
  position: relative;
}

.event-detail__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 3rem 1rem;
  max-width: 600px;
  margin: 0px auto;
  overflow: auto;
}

.event-detail__secondary-card,
.event-detail__third-card {
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 1rem;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.event-detail__section-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.event-detail__tickets-amount {
  font-size: 1.2rem;
}

.event-detail__address-info {
  font-size: 1rem;
  color: #333;
  line-height: 1.4;
}

.event-detail__contacts-info {
  font-size: 1rem;
  color: #333;
  line-height: 1.4;
}

.event-detail__organizer {
  display: flex;
  align-items: flex-end;
  gap: 7px;
  margin-bottom: 1rem;
}

.event-detail__organizer-logo {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.event-detail__organizer-name {
  font-size: 1.3rem;
  font-weight: 500;
}

.event-detail__button {
  height: 50px;
  width: 100%;
  background-color: var(--yellow);
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 15px;
}
