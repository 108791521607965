.shops-list {
  padding: 50px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shops-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.shop-item {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 300px;
}

.shop-item img {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  object-fit: cover;
  object-position: center;
}

.shop-item p {
  font-size: 20px;
  padding: 10px 20px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  background-color: white;
  margin-top: -30px;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .shops-list {
    padding: 10px;
  }

  .shops-list-container {
    gap: 15px;
  }

  .shop-item {
    width: 30%;
  }

  .shop-item img {
    width: 100px;
    height: 100px;
  }

  .shop-item p {
    max-width: 120px;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    margin-top: -20px;
    border-radius: 10px;
  }
}
