.cart-item {
  display: grid;
  grid-template-columns: 100px 1fr 150px 50px;
  align-items: center;
  padding: 10px;
  gap: 20px;
  border-bottom: 1px solid #ccc;
  transition: all 0.3s;
}

.cart-item:hover {
  border: 1px solid var(--yellow);
  box-shadow: 1px 1px 10px var(--yellow);
  overflow: visible;
}

.cart-item div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.cart-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px 0;
}

.shop-name {
  font-weight: 500;
  font-size: 25px;
}

.item-cost {
  font-weight: 600;
  text-align: end;
}

.delete-from-cart {
  cursor: pointer;
  background: rgb(211, 76, 76);
  color: white;
  border: none;
  padding: 5px;
  width: 80px;
  border-radius: 5px;
  margin-top: 10px;
}

.order-units-list {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s, opacity 0.5s;
  opacity: 0;
  border: 2px solid rgb(224, 224, 224);
  border-top: none;
  margin-top: 10px;
}

.order-units-list.show {
  max-height: 100%;
  opacity: 1;
}

.mapboxgl-control-container {
  display: none;
}

.unit-delivery-buttons {
  display: flex;
  gap: 20px;
  margin: 30px 0;
}

.unit-delivery-buttons button {
  border: none;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 15px;
  min-width: 80px;
  color: #26585f;
  cursor: pointer;
}

.unit-delivery-buttons .choosed-delivery-button {
  background-color: var(--yellow);
}

.manual-address-input {
  display: flex;
  align-items: top;
  gap: 20px;
}

.manual-address-input .address-input {
  width: 100%;
  background-color: transparent;
  padding: 10px;
  border: none;
  border-bottom: 1px solid var(--dark-green);
  border-radius: 5px;
  outline: none;
  margin-bottom: 10px;
}

.manual-address-input .address-input-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: top;
  padding: 0;
}

.manual-address-input .address-input.error {
  border-bottom: 1px solid red;
}

.manual-address-input .address-input.error::placeholder {
  color: red;
}

.error-text {
  color: red;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.user-address-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user-address-label {
  display: flex;
  gap: 10px;
  display: flex;
  gap: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
  letter-spacing: 1px;
  background: linear-gradient(90deg, var(--yellow), #fad0c4);
  padding: 5px 10px;
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.delivery-address-title {
  margin: 10px 0;
  font-weight: 600;
}

.no-delivery-address-title {
  margin: 10px 0;
  font-weight: 500;
  font-size: 15px;
}

.cart-item-add-address-button {
  border: none;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
  background-color: #26585f;
  color: white;
  cursor: pointer;
  height: fit-content;
  margin: 20px 0;
}

.cart-item-add-address-button:hover {
  color: var(--yellow);
}

.custom-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999; /* Ensure it appears on top */
  width: auto; /* Adjust the width as needed */
}

@media screen and (max-width: 768px) {
  .cart-item {
    display: grid;
    grid-template-columns: 70px 1fr 80px 25px;
    align-items: center;
    padding: 5px;
    gap: 10px;
    max-width: 400px;
  }

  .cart-item img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin: 5px;
  }

  .shop-name {
    font-weight: 700;
    font-size: 14px;
  }

  .delete-from-cart {
    cursor: pointer;
    background: rgb(211, 76, 76);
    color: white;
    border: none;
    padding: 5px;
    width: 50px;
    border-radius: 5px;
    margin-top: 10px;
  }

  .order-units-list {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s, opacity 0.5s;
    opacity: 0;
    border: 2px solid rgb(224, 224, 224);
    border-top: none;
    margin-top: 5px;
  }

  .order-units-list.show {
    max-height: 100%;
    opacity: 1;
  }

  .amount {
    font-size: 13px;
  }

  .unit-delivery {
    display: flex;
    gap: 20px;
    margin: 5px 0;
  }

  .cart-item-add-address-button {
    padding: 7px 10px;
    margin: 0;
  }

  .user-address-label {
    font-size: 12px;
  }

  .unit-delivery button {
    border: none;
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
    font-size: 12px;
    min-width: 50px;
    color: #26585f;
    cursor: pointer;
  }

  .Order_shop-address {
    font-size: 12px;
  }

  .user-address-list {
    margin-bottom: 20px;
  }

  .unit-delivery .choosed-delivery-button {
    background-color: var(--yellow);
  }

  .delivery-address-title {
    margin: 10px 0;
  }

  .no-delivery-address-title {
    margin: 10px 0;
    font-weight: 600;
    font-size: 13px;
  }

  .item-cost {
    font-size: 12px;
  }

  .cart-payment-button {
    margin: 10px 0;
  }

  .unit-delivery-buttons {
    margin: 20px 0 10px 0;
  }
}
