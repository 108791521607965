.header {
  width: 100vw;
  background-color: white;
  min-height: 100px;
  padding: 0px 40px;
  display: flex;
}

.header-content {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-logo {
  height: 45px;
  cursor: pointer;
}

.cart-icon {
  color: var(--yellow);
  cursor: pointer;
}

.shopping-cart-logo {
  position: relative;
  cursor: pointer;
}

.shopping-cart-amount {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: -2px;
  right: -5px;
  font-weight: 600;
  color: white;
  background-color: var(--dark-green);
}

.log-buttons {
  position: absolute;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
}

.log-buttons button {
  border: none;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 15px;
  min-width: 80px;
  color: #26585f;
  cursor: pointer;
}

.login,
.logout {
  background-color: var(--yellow);
}

.register {
  background-color: var(--light-gray);
}

.notification-icon {
  color: var(--yellow);
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0;
    min-height: 70px;
  }

  .header-content {
    width: 100%;
  }

  .main-logo {
    height: 32px;
    margin-right: 10px;
  }

  .log-buttons {
    gap: 10px;
    right: 20px;
  }

  .log-buttons button {
    font-size: 10px;
    padding: 7px 10px;
    min-width: 40px;
  }

  .header
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge.cart-icon.css-19vn4a9-MuiSvgIcon-root {
    width: 30px;
    height: 25px;
  }

  .shopping-cart-amount {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    bottom: -2px;
    right: -5px;
    font-weight: 600;
    font-size: 12px;
  }
}
