.events {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 80%;
  margin: 30px auto;
}

.events-list-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.events-list-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.your-tickets {
  font-size: 1.7rem;
  color: #fbde00;
  cursor: pointer;
  text-decoration: none;
}

.events-list-controls {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.events-list-controls select {
  cursor: pointer;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  background-color: var(--light-gray);
  outline: none;
  text-decoration: none;
  color: black
}


@media screen and (max-width: 600px) {
  .events {
    max-width: 90%;
  }

  .events-list-title {
    font-size: 1.5rem;
  }

  .your-tickets {
    font-size: 1.2rem;
  }

  .events-list-controls select {
    font-size: 13px;
    
  }

}
