.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  height: 50px;
}

.nav-list {
  width: 90%;
  display: flex;
  align-items: center;
  list-style-type: none;
  justify-content: space-between;
  margin: 0 auto;
}

.nav-list li {
  flex: 1;
  position: relative;
}

.nav-list li a {
  text-decoration: none;
  color: var(--dark-green);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-weight: 500;
}

.nav-list li a:hover {
  color: rgb(255, 221, 0);
}

.nav-list li a.active {
  color: rgb(255, 221, 0);
}

.about-us-box {
  width: 230px;
  height: fit-content;
  background-color: white;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: -10px;
  border-radius: 10px;

  display: none;
  transition: display 2s linear;
  color: var(--dark-green);
  font-size: 15px;
  padding: 20px 15px;
  cursor: default;
}

.about-us-box.active {
  display: flex;
  flex-direction: column;
}

.about-us-box h3 {
  margin-bottom: 10px; 
}

@media screen and (max-width: 768px) {
  .nav-content {
    position: fixed;
    bottom: 0;
    height: 70px;   
    z-index: 1000;
  }

  .nav-list {
    width: 100%;
  }

  .nav-list li a {
    flex-direction: column;
    font-size: 10px;
    align-items: center;
    justify-content: space-around;
    gap: 3px;
    font-weight: 500;
    padding: 10px;
  }

  .about-us-box {
    width: 150px;
    font-size: 10px;
    bottom: 55px;
    top:auto;
    right: 5px;
    padding: 10px;
  }
}
