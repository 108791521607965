.add-category {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10px;
  gap: 40px;
}

.add-category p {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: var(--dark-green);
}

.add-category-photo {
  position: relative;
}

.add-category-photo label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-category-photo label div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--dark-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--yellow);
  font-size: 50px;
  padding-bottom: 8px;
}

.add-category-form {
  margin: 20px auto;
  display: flex;
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  gap: 30px;
}

.add-category-form input,
.add-category-form select {
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  background-color: transparent;
  border-bottom: 1px solid var(--dark-green);
}

.add-category-form button {
  width: 100%;
  height: 50px;
  background-color: var(--yellow);
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.category-img-preview {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .add-category {
    gap: 20px;
    padding: 20px 5px;
  }

  .add-category-form {
    margin: 0 auto;
    gap: 15px;
  }

  .add-category-form button {
    height: 40px;
    background-color: var(--yellow);
    color: white;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
  }

  .add-category-form input,
  .add-category-form select {
    width: 100%;  
  }

  .category-img-preview {
    width: 200px;
    height: 200px;
  }
}
