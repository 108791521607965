.add-cart-button {
    z-index: 1;
}

.added-to-cart:disabled {
  opacity: 1;
  background-color: transparent;
  color: var(--dark-green);
  cursor: default;
  max-width: fit-content;
}

.add-to-cart-button {
  background-color: var(--yellow);
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  max-width: fit-content;
  white-space: nowrap;
}

.add-to-cart-button:disabled {
  opacity: 0.5;
  background-color: var(--yellow);
}
