.ticket-item {
  display: flex;
  justify-content: space-between;
  background-color: var(--light-gray);
  padding: 20px;
  border-radius: 15px;
  align-items: center;
  cursor: pointer;
}

.ticket-item__info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 17px;
}

.ticket-item__title {
  font-weight: 600;
  font-size: 19px;
}

.ticket-item__price {
  color: var(--yellow);
  font-weight: 600;
}

.ticket-item__status {
  font-weight: 500;
}

.ticket-item__qr-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.ticket-item__qr-block img {
  width: 90px;
  height: 90px;
}

@media screen and (max-width: 768px) {
  .ticket-item {
    padding: 15px;
  }

  .ticket-item__info {
    font-size: 15px;
    gap: 10px;
  }

  .ticket-item__title {
    font-size: 17px;
  }

  .ticket-item__qr-block img {
    width: 60px;
    height: 60px;
  }
}
