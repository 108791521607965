.form-header {
  display: flex;
  align-items: center;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #e1e8ee;
}

.form-body {
  height: auto;
  padding: 20px 0 0 0;
}

.form-header .title {
  font-size: 30px;
  margin: 0;
  color: #5e6977;
}

.card-number,
.cvv-input input,
.month select,
.year select {
  font-size: 14px;
  font-weight: 100;
  line-height: 14px;
}

.card-number,
.month select,
.year select {
  font-size: 14px;
  font-weight: 100;
  line-height: 14px;
}

.card-number,
.cvv-details,
.cvv-input input,
.month select,
.year select {
  color: #86939e;
}

.card-number,
.cvv-input input,
.month select,
.paypal-btn,
.proceed-btn,
.year select {
  height: 42px;
}

.card-number {
  width: 100%;
  margin-bottom: 20px;
  padding-left: 20px;
  border: 2px solid #e1e8ee;
  border-radius: 6px;
}

.month select,
.year select {
  width: 47%;
  min-width: 190px;
  margin-bottom: 20px;
  padding-left: 20px;
  border: 2px solid #e1e8ee;
  border-radius: 6px;
  background-position: 85% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.month select {
  float: left;
}

.year select {
  float: right;
}

.cvv-input input {
  float: left;
  padding-left: 20px;
  border: 2px solid #e1e8ee;
  border-radius: 6px;
}


.cvv-details {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  float: right;
  flex: 1.5;
}

.cvv-details p {
}

.card-verification {
  width: 100%;
  display: flex;
  max-width: 500px;
  gap: 10px;
  align-items: center;
}

.paypal-btn,
.proceed-btn {
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  border-color: transparent;
  border-radius: 6px;
}

.proceed-btn {
  margin-top: 40px;
  color: white;
  background: var(--dark-green);
}

.proceed-btn:disabled {
  background: #26585f;
  opacity: 0.5;
  cursor: not-allowed;
}

.paypal-btn a,
.proceed-btn a {
  text-decoration: none;
}

.proceed-btn a {
  color: #fff;
}

@media screen and (max-width: 857px) {
  .form-header .title {
    font-size: 23px;
  }

  .modal-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .month select,
  .year select{
    min-width: 145px;
  }

  .cvv-input input {
    width: 80px;
  }
}
